import React from "react";
import { Link } from "react-router-dom";
import { siteData } from "../utils/utils";

export const DonationError = () => {
  return (
    <div className="donation-response">
      <div className="inner">
        <h1>{siteData.error.title}</h1>
        <p>{siteData.error.message}</p>
        <Link to="/" className="button">
          {" "}
          Riprova &nbsp;
          <svg x="0px" y="0px" viewBox="0 0 30 20">
            <g>
              <polygon points="30,10 20.1,19.9 18,17.8 24.3,11.5 0,11.5 0,8.5 24.3,8.5 18,2.2 20.1,0.1 	"></polygon>
            </g>
          </svg>
        </Link>
      </div>
    </div>
  );
};
