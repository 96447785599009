import React from "react";
import { getTheme, siteData, Theme } from "../utils/utils";

export const DonationSuccess = () => {
  const theme: Theme = getTheme();

  const data = siteData.themes[theme];

  return (
    <div className="donation-response">
      <div className="inner">
        <h1>{siteData.success.title}</h1>
        <p>{siteData.success.message}</p>
        <a href={data.url} className="button">
          {" "}
          Torna a {data.name} &nbsp;
          <svg x="0px" y="0px" viewBox="0 0 30 20">
            <g>
              <polygon points="30,10 20.1,19.9 18,17.8 24.3,11.5 0,11.5 0,8.5 24.3,8.5 18,2.2 20.1,0.1 	"></polygon>
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};
