import React, { useEffect, useState } from "react";
import { getTheme, siteData, Theme } from "../../utils/utils";
import dissipatioLogo from "../../assets/images/dissipatio-logo.png";
import contrastiLogo from "../../assets/images/contrasti-logo.png";
import { useWindowSize } from "../../hooks/useWindowSize";

export const Intro = () => {
  const theme: Theme = getTheme();
  const data = siteData.themes[theme];

  const [collapsed, setCollapsed] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    if (!size.width) return;
    setCollapsed(size.width < 768);
  }, [size.width]);

  return (
    <div className="intro">
      <div className="logo">
        <a href={data.url} title={data.name}>
          {theme === "dissipatio" && (
            <img src={dissipatioLogo} alt={data.name} />
          )}
          {theme === "contrasti" && <img src={contrastiLogo} alt={data.name} />}
        </a>
      </div>

      <div className="intro-description">
        {theme === "dissipatio" && (
          <>
            <h1>Sostienici! </h1>
            <div className={`description ${collapsed ? "collapsed" : ""}`}>
              <div className="inner">
                <p>
                  Tutti i giorni, la nostra sveglia di redazione suona alle 6
                  del mattino. Dalla primissima alba ascoltiamo la radio, e nel
                  mentre passiamo in rassegna tutte le testate tradizionali e
                  non, nazionali e straniere, dalle riviste ai quotidiani ai
                  siti internet di nicchia. Dopo aver fatto una "cernita", cioè
                  individuato l'1 per cento delle notizie (il restante 99 è
                  letteralmente "cartastraccia") che si pesano come l’oro, che
                  sparigliano, orientano, decidono il corso del dibattito
                  profondo, produciamo un report interno. Successivamente ci
                  riuniamo in presenza o virtualmente per condividere
                  pubblicazioni, visioni, informazioni confidenziali, elaboriamo
                  un timone sui temi da affrontare, per fornirti analisi,
                  scenari, approfondimenti di qualità in maniera totalmente
                  gratuita. Ma il tipo di giornalismo che facciamo richiede
                  molto tempo, nella ricerca come nello sviluppo. Se pensi che
                  valga la pena di incoraggiarci e sostenerci, siamo qui, pronti
                  ad unire tutti i puntini.
                </p>
              </div>
            </div>
            {size.width && size.width < 768 && (
              <button
                className="collapser"
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? "Leggi tutto" : "Chiudi"}
              </button>
            )}
          </>
        )}
        {theme === "contrasti" && (
          <>
            <h2>Supporta! </h2>
            <div className={`description ${collapsed ? "collapsed" : ""}`}>
              <div className="inner">
                <p>
                  Ormai da anni rappresentiamo un’alternativa nella narrazione
                  sportiva italiana: qualcosa che prima non c’era, e dopo di noi
                  forse non ci sarà. In questo periodo abbiamo offerto contenuti
                  accessibili a tutti non chiedendo nulla a nessuno, tantomeno
                  ai lettori. Adesso però il nostro è diventato un lavoro
                  quotidiano, dalla prima rassegna stampa della mattina
                  all’ultima notizia della sera. Tutto ciò ha un costo. Perché
                  la libertà, prima di tutto, ha un costo.
                </p>
                <p>
                  Se ritenete che Contrasti sia un modello virtuoso, un punto di
                  riferimento o semplicemente un coro necessario nell'arena
                  sportiva (anche quando non siete d’accordo), sosteneteci: una
                  piccola donazione per noi significa molto, innanzitutto il
                  riconoscimento del lavoro di una redazione che di compromessi,
                  nella vita, ne vuole fare il meno possibile. Ora e sempre, il
                  cuore resterà il nostro tamburo.
                </p>
              </div>
            </div>
            {size.width && size.width < 768 && (
              <button
                className="collapser"
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? "Leggi tutto" : "Chiudi"}
              </button>
            )}
          </>
        )}
      </div>

      <div className="copy">
        {data.name} fa parte del{" "}
        <a href="https://gruppomagog.it/" target="blank">
          Gruppo Magog
        </a>{" "}
        {" - "}
        <a href={data.privacy} target="blank">
          Privacy
        </a>
      </div>
    </div>
  );
};
