import React, { useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import stripeLogo from "../../assets/images/stripe.png";
import { getTheme, IPrice, prices, Theme } from "../../utils/utils";

type DonationFormProps = {
  refMode: Tmode;
  refAmount: number;
};

type Tmode = "subscription" | "payment";

export const DonationForm = (props: DonationFormProps) => {
  const { refMode, refAmount } = props;

  const theme: Theme = getTheme();

  const [formPrices, setFormPrices] = useState<IPrice[]>(
    prices[theme][refMode]
  );

  const [isCustomAmount, setIsCustomAmount] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number | null>(null);
  const [mode, setMode] = useState<Tmode>(refMode);
  const [selectedPrice, setSelectedPrice] = useState<IPrice>(
    formPrices.find(d => d.value === refAmount) || formPrices[2]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const [formState, setFormState] = useState({
    loading: false,
    error: false,
  });

  let stripePromise: any;

  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
    }
    return stripePromise;
  };

  const handleMode = (m: Tmode) => {
    setMode(m);
    setFormPrices(prices[theme][m]);
    setSelectedPrice(prices[theme][m][2]);
    setIsCustomAmount(false);
    setQuantity(null);
  };

  const handlePrice = (price: IPrice) => {
    setIsCustomAmount(price.custom);
    setSelectedPrice(price);
    setQuantity(0);

    if (price.custom) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleInput = (e: any) => {
    const value = +e.target.value;

    if (value <= 0) {
      setQuantity(null);
    } else {
      setQuantity(Math.round(value));
    }
  };

  const resetError = () => {
    setFormState({
      ...formState,
      error: false,
    });
  };

  const onSubmit = async () => {
    setFormState({
      ...formState,
      loading: true,
    });

    const item = {
      price: selectedPrice?.id,
      quantity: isCustomAmount ? quantity : 1,
    };

    const checkoutOptions = {
      lineItems: [item],
      mode,
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/error`,
    };

    try {
      const stripe = await getStripe();
      await stripe.redirectToCheckout(checkoutOptions);
      setFormState({
        ...formState,
        loading: false,
      });
    } catch (e) {
      console.log(e);
      setFormState({
        loading: false,
        error: true,
      });
    }
  };

  // useEffect(() => {
  //   setFormPrices(prices[theme][mode]);
  //   setSelectedPrice(prices[theme][mode][2]);
  //   setIsCustomAmount(false);
  //   setQuantity(null);
  // }, [mode, theme]);

  return (
    <div className="donation-form">
      <div className="mode-container">
        <button
          className={`button ${mode === "payment" ? "active" : ""}`}
          onClick={() => handleMode("payment")}
        >
          Singola
        </button>
        <button
          className={`button ${mode === "subscription" ? "active" : ""}`}
          onClick={() => handleMode("subscription")}
        >
          Mensile
        </button>
      </div>

      <div className="prices-container">
        {formPrices.map(price => {
          return (
            <div
              key={price.id}
              className={`button ${
                selectedPrice?.id === price.id ? "active" : ""
              }`}
            >
              <>
                {price.custom ? (
                  <>
                    {isCustomAmount ? (
                      <div className="input-container">
                        €{" "}
                        <input
                          type="number"
                          onInput={handleInput}
                          value={quantity || ""}
                          placeholder="Inserisci importo"
                          ref={inputRef}
                        />
                      </div>
                    ) : (
                      <button onClick={() => handlePrice(price)}>Altro</button>
                    )}
                  </>
                ) : (
                  <button key={price.id} onClick={() => handlePrice(price)}>
                    {price.value} €
                  </button>
                )}
              </>
            </div>
          );
        })}
      </div>

      <div className="submit-container">
        <button
          className="submit"
          onClick={onSubmit}
          disabled={
            formState.loading ||
            (isCustomAmount && (!quantity || quantity! <= 0))
          }
        >
          {formState.loading ? (
            "Loading"
          ) : (
            <>
              <div className="submit-content">
                <span>
                  Sostienici per{" "}
                  {isCustomAmount ? quantity : selectedPrice?.value} €{" "}
                  {mode === "subscription" && "/mese"}
                </span>
                <div className="stripe">
                  pagamenti sicuri con carte di credito tramite{" "}
                  <img src={stripeLogo} alt="stripe" />
                </div>
              </div>
            </>
          )}
        </button>

        {formState.error && (
          <div className="stripe-error">
            <span>Errore durante il reindirizzamento a stripe</span>
            <button onClick={resetError}>x</button>
          </div>
        )}
      </div>
    </div>
  );
};
