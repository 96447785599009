export const getTheme = () => {
  let theme: Theme = "dissipatio";

  if (window.location.href.includes("dissipatio")) {
    theme = "dissipatio";
  }
  if (window.location.href.includes("contrasti")) {
    theme = "contrasti";
  }

  return theme;
};

type TData = {
  url: string;
  name: string;
  privacy: string;
};
export type Theme = "contrasti" | "dissipatio";

type TSiteData = {
  success: {
    message: string;
    title: string;
  };
  error: {
    message: string;
    title: string;
  };
  themes: {
    [key in Theme]: TData;
  };
};

export const siteData: TSiteData = {
  success: {
    message: "Ti ringraziamo per il tuo prezioso contributo. In alto i cuori.",
    title: "Donazione effettuata",
  },
  error: {
    message: "Siamo spiacenti, si è verificato un errore durante il pagamento",
    title: "Errore",
  },
  themes: {
    dissipatio: {
      url: "https://www.dissipatio.it",
      name: "Dissipatio",
      privacy: "https://www.dissipatio.it/privacy-policy/",
    },
    contrasti: {
      url: "https://www.rivistacontrasti.it",
      name: "Contrasti",
      privacy: "https://www.rivistacontrasti.it/privacy-policy/",
    },
  },
};

export type TMode = "payment" | "subscription";

export interface IPrice {
  id: string;
  mode: TMode;
  value: number;
  custom: boolean;
}

export type IPrices = {
  [key in Theme]: {
    payment: IPrice[];
    subscription: IPrice[];
  };
};

export const prices: IPrices = {
  dissipatio: {
    payment: [
      {
        id: "price_1LMCuNDkUsoc8DMDI33eSBGz",
        mode: "payment",
        value: 5,
        custom: false,
      },
      {
        id: "price_1LMCvNDkUsoc8DMDDIj9ha4T",
        mode: "payment",
        value: 10,
        custom: false,
      },
      {
        id: "price_1LMCvlDkUsoc8DMDB4pX8YPM",
        mode: "payment",
        value: 20,
        custom: false,
      },
      {
        id: "price_1LMCwNDkUsoc8DMDctaMPMEN",
        mode: "payment",
        value: 50,
        custom: false,
      },
      {
        id: "price_1LMCwsDkUsoc8DMDbrg7xRaY",
        mode: "payment",
        value: 100,
        custom: false,
      },
      {
        id: "price_1LMCxpDkUsoc8DMDlCN0ttFW",
        mode: "payment",
        value: 0,
        custom: true,
      },
    ],
    subscription: [
      {
        id: "price_1LMD7zDkUsoc8DMDsSPmsauJ",
        mode: "subscription",
        value: 2,
        custom: false,
      },
      {
        id: "price_1LMD8dDkUsoc8DMDWOKNURIE",
        mode: "subscription",
        value: 5,
        custom: false,
      },
      {
        id: "price_1LMD9BDkUsoc8DMD16zmkFm6",
        mode: "subscription",
        value: 10,
        custom: false,
      },
      {
        id: "price_1LMD9ZDkUsoc8DMDelOgkHqD",
        mode: "subscription",
        value: 20,
        custom: false,
      },
      {
        id: "price_1LMDA3DkUsoc8DMDo5L3DlNe",
        mode: "subscription",
        value: 50,
        custom: false,
      },
      {
        id: "price_1LMDAUDkUsoc8DMDVLlZXBQt",
        mode: "subscription",
        value: 0,
        custom: true,
      },
    ],
  },
  contrasti: {
    payment: [
      {
        id: "price_1LMDGhDkUsoc8DMD92jvVKUN",
        mode: "payment",
        value: 5,
        custom: false,
      },
      {
        id: "price_1LMDH6DkUsoc8DMDoGHCxT0t",
        mode: "payment",
        value: 10,
        custom: false,
      },
      {
        id: "price_1LMDHjDkUsoc8DMDjPqLHFJ4",
        mode: "payment",
        value: 20,
        custom: false,
      },
      {
        id: "price_1LMDI0DkUsoc8DMDDAnonysx",
        mode: "payment",
        value: 50,
        custom: false,
      },
      {
        id: "price_1LMDILDkUsoc8DMD1Gz5Egdz",
        mode: "payment",
        value: 100,
        custom: false,
      },
      {
        id: "price_1LMDIlDkUsoc8DMDvMOcRFVH",
        mode: "payment",
        value: 0,
        custom: true,
      },
    ],
    subscription: [
      {
        id: "price_1LMDJODkUsoc8DMDnITxUmbZ",
        mode: "subscription",
        value: 2,
        custom: false,
      },
      {
        id: "price_1LMDJtDkUsoc8DMDBGd6sAge",
        mode: "subscription",
        value: 5,
        custom: false,
      },
      {
        id: "price_1LMDKFDkUsoc8DMD1xdZTBfJ",
        mode: "subscription",
        value: 10,
        custom: false,
      },
      {
        id: "price_1LMDKXDkUsoc8DMD0irr3U9Q",
        mode: "subscription",
        value: 20,
        custom: false,
      },
      {
        id: "price_1LMDKvDkUsoc8DMDkUP7aZR3",
        mode: "subscription",
        value: 50,
        custom: false,
      },
      {
        id: "price_1LMDLFDkUsoc8DMDaowVtIKb",
        mode: "subscription",
        value: 0,
        custom: true,
      },
    ],
  },
};
