import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { DonationSuccess } from "./pages/donation-success";
import { DonationError } from "./pages/donation-error";
import "@stripe/stripe-js";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/success" element={<DonationSuccess />} />
          <Route path="/error" element={<DonationError />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
