import React from "react";
import { DonationForm } from "../components/donation-form/donation-form";
import { Intro } from "../components/intro/intro";

type Tmode = "subscription" | "payment";

export const Home = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const refMode = urlParams.get("mode");
  const refAmount = urlParams.get("amount");

  const mode: Tmode = refMode === "subscription" ? refMode : "payment";

  return (
    <>
      <div className="left-col">
        <Intro />
      </div>
      <div className="right-col">
        <DonationForm refAmount={refAmount ? +refAmount : 20} refMode={mode} />
      </div>
    </>
  );
};
